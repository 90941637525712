import React from "react"

class NotFoundPage extends React.Component {
  render() {

    return (
      <div>
        <h1>Not Found</h1>
        <p>page not found.</p>
      </div>
    )
  }
}

export default NotFoundPage
